import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, TextField, Pane, AccordionSet, Accordion, Select, } from "@folio/stripes/components";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import { StripesConnectedSource } from "@folio/stripes/smart-components";
import { fetchCollections } from "../../api/collectionsService";
import { CollectionType } from "../../types/Collection";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var CollectionsList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var _a = useState({
        type: "",
        name: "",
    }), filters = _a[0], setFilters = _a[1];
    // Track the current input values, without directly modifying filters until applied
    var _b = useState(""), tempTypeFilter = _b[0], setTempTypeFilter = _b[1];
    var _c = useState(""), tempNameFilter = _c[0], setTempNameFilter = _c[1];
    var _d = useState(true), filterPaneIsVisible = _d[0], setFilterPaneIsVisible = _d[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        setFilters({
            type: queryParams.get("type") || "",
            name: queryParams.get("name") || "",
        });
        setTempTypeFilter(queryParams.get("type") || "");
        setTempNameFilter(queryParams.get("name") || "");
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.type) {
            params.set("type", filters.type);
        }
        if (filters.name) {
            params.set("name", filters.name);
        }
        history.push({ search: params.toString() });
    };
    var handleApplyFilters = function () {
        // Update filters with the current input values and update the URL
        setFilters({
            type: tempTypeFilter,
            name: tempNameFilter,
        });
        updateUrlWithFilters({
            type: tempTypeFilter,
            name: tempNameFilter,
        });
    };
    var handleClearFilters = function () {
        setTempTypeFilter("");
        setTempNameFilter("");
        setFilters({
            type: "",
            name: "",
        });
        history.push({ search: "" });
    };
    var isAnyFilterActive = function () {
        return !!filters.type || !!filters.name;
    };
    var _e = useQuery(["collections", filters], function () { return fetchCollections(ky, filters); }, {
        enabled: true, // Enable even if no filters are active to show all records by default
        staleTime: 0,
        cacheTime: 0,
    }), _f = _e.data, collectionsData = _f === void 0 ? [] : _f, isLoading = _e.isLoading, isError = _e.isError;
    var columns = [
        {
            name: "Název",
            key: "mainName",
            clickable: true,
            onClick: function (item) {
                history.push("/bragency/collections/".concat(item.id));
            },
        },
        {
            name: "Typ",
            key: "type",
        },
        {
            name: "ISSN",
            key: "issn",
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return collectionsData; };
    source.resultCount = function () { return collectionsData.length; };
    source.totalCount = function () { return collectionsData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1n\u00ED" },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters }, "Resetovat v\u0161echno"),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Filtr podle typu a n\u00E1zvu", separator: false },
                            React.createElement(Select, { label: "Typ", value: tempTypeFilter, onChange: function (e) { return setTempTypeFilter(e.target.value); }, fullWidth: true },
                                React.createElement("option", { value: "" }, "-- V\u0161echny --"),
                                React.createElement("option", { value: CollectionType.PUBLISHER_SERIES }, "PUBLISHER_SERIES"),
                                React.createElement("option", { value: CollectionType.SERIES }, "SERIES")),
                            React.createElement(TextField, { label: "N\u00E1zev", value: tempNameFilter, onChange: function (e) { return setTempNameFilter(e.target.value); }, fullWidth: true }),
                            React.createElement(Button, { onClick: handleApplyFilters, marginBottom0: true }, "Aplikovat")))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "Seznam kolekc\u00ED" },
                React.createElement(TableComponent, { data: collectionsData, columns: columns, pageSize: 30, searchTerm: "", hasDataFetched: isAnyFilterActive, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })))));
};
export default CollectionsList;

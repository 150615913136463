var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var getHeaders = function () { return ({
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
}); };
export var fetchAgencyPrefixes = function (ky, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, agencies, _b, offset, _c, limit, queryParts, agencyQueries, queryParam, response, data;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _a = filters.agencies, agencies = _a === void 0 ? [] : _a, _b = filters.offset, offset = _b === void 0 ? 0 : _b, _c = filters.limit, limit = _c === void 0 ? 1000 : _c;
                queryParts = [];
                if (agencies.length > 0) {
                    agencyQueries = agencies.map(function (agency) { return "agency==\"".concat(agency, "\""); }).join(" or ");
                    queryParts.push("(".concat(agencyQueries, ")"));
                }
                queryParam = queryParts.length > 0 ? "query=".concat(encodeURIComponent(queryParts.join(' and '))) : 'query=cql.allRecords=1';
                return [4 /*yield*/, ky.get("agency-prefixes?".concat(queryParam, "&offset=").concat(offset, "&limit=").concat(limit), { headers: getHeaders() })];
            case 1:
                response = _d.sent();
                if (!response.ok)
                    throw new Error("Fetch error: ".concat(response.statusText));
                return [4 /*yield*/, response.json()];
            case 2:
                data = _d.sent();
                return [2 /*return*/, data.agencyPrefixes];
        }
    });
}); };
export var createAgencyPrefix = function (ky, agencyPrefix) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, ky.post("agency-prefixes", {
                        json: agencyPrefix,
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
            case 3:
                error_1 = _a.sent();
                console.error("Error creating agency prefix:", error_1);
                throw error_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var deleteAgencyPrefix = function (ky, id) { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.delete("agency-prefixes/".concat(id), {
                        headers: getHeaders(),
                    })];
            case 1:
                _a.sent();
                console.log("Agency prefix deleted successfully");
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                console.error("Error deleting agency prefix:", error_2);
                throw error_2;
            case 3: return [2 /*return*/];
        }
    });
}); };

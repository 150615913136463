import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AresUpdatesList from "./AresUpdatesList";
import AresUpdatesEdit from "./AresUpdatesEdit";
function AresUpdates() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: AresUpdatesList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), component: AresUpdatesEdit })));
}
export default AresUpdates;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from "react";
import { useQuery, } from "react-query";
import { Pane, Select, Button, Row, Col, TextArea, Datepicker, AccordionSet, Accordion, Checkbox, Selection, PaneFooter, ErrorModal, } from "@folio/stripes/components";
import { useHistory } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import debounce from "lodash/debounce";
import { createPublisher, fetchAddress, fetchAddressCode, fetchRegions, lookupPublisherByCompanyNumber, } from "../../api/publisherService";
import TextFieldTrimmed from "../../components/TextFieldTrimmed";
var PublisherCreate = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var history = useHistory();
    var ky = useOkapiKy();
    var _j = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _j[0], setAccordionStatus = _j[1];
    var _k = useState({
        companyNumber: "",
        name: "",
        nameSuffix: "",
        qualifier: "",
        legalForm: "LEGAL",
        taxNumber: "",
        birthDate: "",
        mainAddress: {
            addressCode: "",
            street: "",
            postalCode: "",
            municipality: "",
            region: { id: "", name: "" }, // New field
        },
        mailingAddress: {
            addressCode: "",
            street: "",
            postalCode: "",
            municipality: "",
            region: { id: "", name: "" }, // New field
        },
        contacts: [],
        alternativeNames: [],
        internalComment: "",
    }), formValues = _k[0], setFormValues = _k[1];
    var _l = useState(false), enableMainAddress = _l[0], setEnableMainAddress = _l[1];
    var _m = useState(false), enableMailingAddress = _m[0], setEnableMailingAddress = _m[1];
    var _o = useState({}), errors = _o[0], setErrors = _o[1];
    var _p = useState(""), errorMessage = _p[0], setErrorMessage = _p[1];
    var _q = useState(false), errorModalOpen = _q[0], setErrorModalOpen = _q[1];
    var _r = useState(false), showMailingAddress = _r[0], setShowMailingAddress = _r[1];
    var _s = useState(true), expandAll = _s[0], setExpandAll = _s[1];
    var _t = useState([]), regions = _t[0], setRegions = _t[1]; // State for regions
    var _u = useState(""), selectedRegion = _u[0], setSelectedRegion = _u[1];
    var _v = useState(""), addressQuery = _v[0], setAddressQuery = _v[1]; // Holds the current input text for the query
    var _w = useState(""), selectedAddress = _w[0], setSelectedAddress = _w[1]; // Holds the selected addressCode
    var _x = useState([]), addressData = _x[0], setAddressData = _x[1]; // State for address data
    var _y = useState(false), addressLoading = _y[0], setAddressLoading = _y[1]; // Loading state for addresses
    var _z = useState(""), mailingAddressQuery = _z[0], setMailingAddressQuery = _z[1]; // For the mailing address query
    var _0 = useState(""), selectedMailingAddress = _0[0], setSelectedMailingAddress = _0[1]; // Holds selected mailing address
    var _1 = useState([]), mailingAddressData = _1[0], setMailingAddressData = _1[1]; // Holds mailing addresses
    var _2 = useState(false), mailingAddressLoading = _2[0], setMailingAddressLoading = _2[1]; // Loading state for mailing addresses
    // Debounced function to delay the query call as the user types
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    // Handle input changes in the Selection component
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true);
                    console.log(addressData.map(function (address) { return ({
                        value: address.addressCode,
                        label: address.address,
                    }); }));
                    console.log("Input value for address search:");
                    return [4 /*yield*/, debouncedSetAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAddressSelection = function (selectedValue) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setSelectedAddress(selectedValue);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails_1 = _a.sent();
                    setSelectedRegion(addressDetails_1.region.id);
                    setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { mainAddress: {
                            addressCode: selectedValue,
                            street: addressDetails_1.street,
                            postalCode: addressDetails_1.postalCode,
                            municipality: addressDetails_1.municipality,
                            region: addressDetails_1.region || { id: "", name: "" },
                        } })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching address details:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]); // Clear addresses in case of an error
        });
    }, [addressQuery]);
    var debouncedSetMailingAddressQuery = useCallback(debounce(function (inputValue) {
        setMailingAddressQuery(inputValue);
    }, 300), []);
    var handleMailingAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setMailingAddressLoading(true);
                    return [4 /*yield*/, debouncedSetMailingAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setMailingAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleMailingAddressSelection = function (selectedValue) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails_2, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setSelectedMailingAddress(selectedValue);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails_2 = _a.sent();
                    console.log("Fetched address:", addressDetails_2);
                    setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { mailingAddress: {
                            addressCode: selectedValue,
                            street: addressDetails_2.street,
                            postalCode: addressDetails_2.postalCode,
                            municipality: addressDetails_2.municipality,
                            region: addressDetails_2.region || { id: "", name: "" }, // Add this field with a default value
                        } })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error fetching mailing address details:", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchAddress(ky, mailingAddressQuery)
            .then(function (addresses) {
            setMailingAddressData(addresses);
        })
            .catch(function (error) {
            console.error("Error fetching mailing addresses:", error);
            setMailingAddressData([]); // Clear addresses in case of an error
        });
    }, [mailingAddressQuery]);
    var _3 = useQuery("regions", function () { return fetchRegions(ky); }, {
        onSuccess: function (data) {
            console.log("Fetched regions:", data);
            setRegions(data); // Set the regions state
        },
    }), _4 = _3.data, regionsData = _4 === void 0 ? [] : _4, regionsLoading = _3.isLoading;
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddressChange = function (e, type) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name == "region") {
            var selectedRegion_1 = regions.find(function (region) { return region.id === value; });
            setFormValues(function (prevFormValues) {
                var _a;
                return (__assign(__assign({}, prevFormValues), (_a = {}, _a[type] = __assign(__assign({}, prevFormValues[type]), { region: selectedRegion_1 }), _a)));
            });
        }
        else {
            setFormValues(function (prevFormValues) {
                var _a, _b;
                return (__assign(__assign({}, prevFormValues), (_a = {}, _a[type] = __assign(__assign({}, prevFormValues[type]), (_b = {}, _b[name] = value, _b)), _a)));
            });
        }
    };
    var handleContactChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var contacts = __spreadArray([], (formValues.contacts || []), true);
        contacts[index] = __assign(__assign({}, (contacts[index] || {})), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { contacts: contacts }));
    };
    var handleAlternativeNameChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var alternativeNames = __spreadArray([], formValues.alternativeNames, true);
        alternativeNames[index] = __assign(__assign({}, alternativeNames[index]), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: alternativeNames }));
    };
    var addContact = function () {
        setFormValues(__assign(__assign({}, formValues), { contacts: __spreadArray(__spreadArray([], formValues.contacts, true), [{ type: "EMAIL", value: "" }], false) }));
    };
    var removeContact = function (index) {
        var contacts = __spreadArray([], formValues.contacts, true);
        contacts.splice(index, 1);
        setFormValues(__assign(__assign({}, formValues), { contacts: contacts }));
    };
    var addAlternativeName = function () {
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: __spreadArray(__spreadArray([], formValues.alternativeNames, true), [
                { name: "", nonPublic: false },
            ], false) }));
    };
    var removeAlternativeName = function (index) {
        var alternativeNames = __spreadArray([], formValues.alternativeNames, true);
        alternativeNames.splice(index, 1);
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: alternativeNames }));
    };
    var handleIco = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newErrors, publisher_1, error_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    newErrors = __assign({}, errors);
                    // Validate ICO
                    if (!formValues.companyNumber || formValues.companyNumber.trim() === "") {
                        newErrors.companyNumber = "".concat(formValues.legalForm ? "Právnická osoba" : "Fyzická osoba", " pot\u0159ebuje I\u010CO.");
                        setErrors(newErrors);
                        return [2 /*return*/];
                    }
                    else if (!/^\d{8}$/.test(formValues.companyNumber)) {
                        newErrors.companyNumber = "IČO musí mít přesně 8 číslic.";
                        setErrors(newErrors);
                        return [2 /*return*/];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, lookupPublisherByCompanyNumber(ky, formValues.companyNumber)];
                case 2:
                    publisher_1 = _c.sent();
                    setFormValues(function (prevFormValues) {
                        var _a, _b, _c;
                        return (__assign(__assign({}, prevFormValues), { name: publisher_1.name, legalForm: (_a = publisher_1.legalForm) !== null && _a !== void 0 ? _a : prevFormValues.legalForm, taxNumber: (_b = publisher_1.taxNumber) !== null && _b !== void 0 ? _b : prevFormValues.taxNumber, contacts: (_c = publisher_1.contacts) !== null && _c !== void 0 ? _c : prevFormValues.contacts }));
                    });
                    return [4 /*yield*/, handleAddressSelection((_b = (_a = publisher_1.mainAddress) === null || _a === void 0 ? void 0 : _a.addressCode) !== null && _b !== void 0 ? _b : "")];
                case 3:
                    _c.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _c.sent();
                    console.error("Error fetching Ares data:", error_3);
                    setErrorMessage("Došlo k chybě při hledáni IČA.");
                    setErrorModalOpen(true);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var validateForm = function () {
        var newErrors = {
            contacts: [],
            alternativeNames: [],
        };
        // Main fields validation
        if (!formValues.name)
            newErrors.name = "Název je povinný.";
        if (formValues.legalForm == "LEGAL")
            setFormValues(__assign(__assign({}, formValues), { birthDate: "" }));
        if (!formValues.birthDate && formValues.legalForm == "NATURAL") {
            newErrors.birthDate = "Fyzická osoba potřebuje Datum narození.";
        }
        else {
            newErrors.birthDate = "";
        }
        // Main Address validation
        if (!formValues.mainAddress.street)
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { street: "Ulice je povinná." });
        if (!formValues.mainAddress.municipality)
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { municipality: "Obec je povinná." });
        if (!formValues.mainAddress.postalCode) {
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { postalCode: "PSČ je povinné." });
        }
        else if (!/^\d{3} ?\d{2}$/.test(formValues.mainAddress.postalCode)) {
            // Check for valid PSČ format
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { postalCode: "PSČ musí mít 5 číslic" });
        }
        if (!formValues.mainAddress.region.id)
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { region: "Kraj je povinný." });
        // Mailing Address validation
        if (showMailingAddress) {
            if (!formValues.mailingAddress.street)
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { street: "Ulice doručení je povinná." });
            if (!formValues.mailingAddress.municipality)
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { municipality: "Obec doručení je povinná." });
            if (!formValues.mailingAddress.postalCode) {
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { postalCode: "PSČ je povinné." });
            }
            else if (!/^\d{3} ?\d{2}$/.test(formValues.mailingAddress.postalCode)) {
                // Check for valid PSČ format
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { postalCode: "PSČ musí mít 5 číslic" });
            }
            if (!formValues.mailingAddress.region.id)
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { region: "Kraj doručení je povinný." });
        }
        var entityType = formValues.legalForm === "LEGAL" ? "Právnická osoba" : "Fyzická osoba";
        // Both "Právnická osoba" (LEGAL) and "Fyzická osoba" (NATURAL) need companyNumber and taxNumber
        if (formValues.legalForm === "LEGAL" ||
            formValues.legalForm === "NATURAL") {
            if (!formValues.companyNumber || formValues.companyNumber.trim() === "") {
                newErrors.companyNumber = "".concat(entityType, " pot\u0159ebuje I\u010CO.");
            }
            else if (!/^\d{8}$/.test(formValues.companyNumber)) {
                newErrors.companyNumber = "IČO musí mít přesně 8 číslic.";
            }
            if (!formValues.taxNumber || formValues.taxNumber.trim() === "") {
                newErrors.taxNumber = "".concat(entityType, " pot\u0159ebuje DI\u010C.");
            }
            else if (
            // Check for format: exactly 2 letters followed by 2 to 13 digits
            !/^[A-Za-z]{2}\d{2,13}$/.test(formValues.taxNumber)) {
                newErrors.taxNumber =
                    "DIČ musí mít 2 písmena následovaná 2 až 13 číslicemi.";
            }
        }
        // Contacts validation
        var contactErrorsArray = [];
        formValues.contacts.forEach(function (contact) {
            var contactErrors = {};
            if (!contact.value) {
                contactErrors.value = "Kontakt je povinný.";
            }
            else {
                switch (contact.type) {
                    case "EMAIL":
                        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.value)) {
                            contactErrors.value =
                                "Hodnota kontaktu typu E-mail není formálně platnou e-mailovou adresou.";
                        }
                        break;
                    case "WEB":
                        if (!/^https?:\/\/[^\s$.?#].[^\s]*$/.test(contact.value)) {
                            contactErrors.value =
                                "Hodnota kontaktu typu Web není formálně platným URL.";
                        }
                        break;
                    case "PHONE":
                        if (!/^\+?[0-9\s\-]{7,15}$/.test(contact.value)) {
                            contactErrors.value =
                                "Hodnota kontaktu typu Telefon má méně než 9 číslic.";
                        }
                        break;
                    case "DATA_BOX":
                        if (contact.value.length < 6 || contact.value.length > 12) {
                            contactErrors.value =
                                "Hodnota kontaktu typu Datová schránka má méně než 6 nebo více než 12 znaků.";
                        }
                        break;
                    default:
                        contactErrors.type = "Typ kontaktu je neznámý.";
                }
            }
            if (!contact.type) {
                contactErrors.type = "Typ kontaktu je povinný.";
            }
            // Always push to maintain index, even if no errors
            contactErrorsArray.push(contactErrors);
        });
        // Remove all empty error objects if all errors are empty
        if (contactErrorsArray.every(function (error) { return Object.keys(error).length === 0; })) {
            newErrors.contacts = [];
        }
        else {
            newErrors.contacts = contactErrorsArray;
        }
        // Alternative names validation
        var alternativeNameErrorsArray = [];
        formValues.alternativeNames.forEach(function (alternativeName) {
            var altNameErrors = {};
            if (!alternativeName.name) {
                altNameErrors.name = "Alternativní název je povinný.";
            }
            if (alternativeName.nameSuffix &&
                alternativeName.nameSuffix.length > 50) {
                altNameErrors.nameSuffix = "Přípona názvu nesmí překročit 50 znaků.";
            }
            if (alternativeName.qualifier && alternativeName.qualifier.length > 50) {
                altNameErrors.qualifier = "Kvalifikátor nesmí překročit 50 znaků.";
            }
            // Always push to maintain index, even if no errors
            alternativeNameErrorsArray.push(altNameErrors);
        });
        // Remove all empty error objects if all errors are empty
        if (alternativeNameErrorsArray.every(function (error) { return Object.keys(error).length === 0; })) {
            newErrors.alternativeNames = [];
        }
        else {
            newErrors.alternativeNames = alternativeNameErrorsArray;
        }
        // Set errors and validate if there are no populated errors
        setErrors(newErrors);
        var hasErrors = Object.values(newErrors).some(function (error) {
            return (Array.isArray(error) &&
                error.some(function (e) { return Object.keys(e).length > 0; })) ||
                (typeof error === "object" && Object.keys(error).length > 0);
        });
        return !hasErrors;
    };
    var expandAllSections = function () {
        setAccordionStatus({
            general: true,
            address: true,
            contacts: true,
            alternativeNames: true,
            comments: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            general: false,
            address: false,
            contacts: false,
            alternativeNames: false,
            comments: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var jsonData, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    // Validate form before submission
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    jsonData = {
                        name: formValues.name,
                        nameSuffix: formValues.nameSuffix,
                        qualifier: formValues.qualifier,
                        alternativeNames: formValues.alternativeNames.map(function (altName) { return ({
                            name: altName.name,
                            nameSuffix: altName.nameSuffix,
                            type: altName.type,
                            nonPublic: altName.nonPublic,
                        }); }),
                        legalForm: formValues.legalForm === "LEGAL" ? "LEGAL" : "NATURAL",
                        companyNumber: formValues.companyNumber,
                        taxNumber: formValues.taxNumber,
                        birthDate: formValues.birthDate,
                        mainAddress: {
                            addressCode: formValues.mainAddress.addressCode,
                            street: formValues.mainAddress.street,
                            postalCode: formValues.mainAddress.postalCode,
                            municipality: formValues.mainAddress.municipality,
                            region: formValues.mainAddress.region,
                        },
                        mailingAddress: showMailingAddress
                            ? {
                                addressCode: formValues.mailingAddress.addressCode,
                                street: formValues.mailingAddress.street,
                                postalCode: formValues.mailingAddress.postalCode,
                                municipality: formValues.mailingAddress.municipality,
                                region: formValues.mailingAddress.region,
                            }
                            : {
                                // If mailingAddress is false, copy values from mainAddress
                                addressCode: formValues.mainAddress.addressCode,
                                street: formValues.mainAddress.street,
                                postalCode: formValues.mainAddress.postalCode,
                                municipality: formValues.mainAddress.municipality,
                                region: formValues.mainAddress.region,
                            },
                        contacts: formValues.contacts.map(function (contact) { return ({
                            type: contact.type,
                            value: contact.value,
                        }); }),
                        internalComment: formValues.internalComment,
                        origin: "AGENCY",
                        status: "ACTIVE",
                        createTime: new Date().toISOString(),
                        dataConfirmationTime: new Date().toISOString(),
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    console.log("Submitting data:", JSON.stringify(jsonData, null, 2));
                    // Await the createPublisher function
                    return [4 /*yield*/, createPublisher(ky, jsonData)];
                case 2:
                    // Await the createPublisher function
                    _a.sent();
                    // Only go back if creation was successful
                    history.goBack();
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    // Set the error message and open the error modal
                    setErrorMessage("Došlo k chybě při vytváření nakladatele.");
                    setErrorModalOpen(true);
                    console.error("Error creating publisher:", error_4);
                    console.log("Submitting data:", JSON.stringify(jsonData, null, 2));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); } }, "Zru\u0161it") }));
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Zalo\u017Een\u00ED nakladatele", footer: footer },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "flex-end" } },
                                React.createElement(TextFieldTrimmed, { label: "I\u010CO", name: "companyNumber", required: true, value: formValues.companyNumber, error: errors.companyNumber, onChange: handleInputChange }),
                                React.createElement(Button, { buttonStyle: "primary", onClick: handleIco, style: {
                                        marginLeft: "16px",
                                        marginBottom: errors.companyNumber ? "2.6rem" : "1rem",
                                    } }, "Na\u010D\u00EDst dle I\u010CO z rejst\u0159\u00EDku")),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "N\u00E1zev", name: "name", value: formValues.name, onChange: handleInputChange, required: true, error: errors.name }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "Dodatek", name: "nameSuffix", value: formValues.nameSuffix, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Forma", name: "legalForm", value: formValues.legalForm, onChange: handleInputChange },
                                    React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                    React.createElement("option", { value: "NATURAL" }, "Fyzick\u00E1 osoba")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "\u010C\u00EDslo DI\u010C", required: true, error: errors.taxNumber, name: "taxNumber", value: formValues.taxNumber, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Datepicker, { label: "Datum narozen\u00ED", value: formValues.birthDate, onChange: function (e, formattedDate, dateString) {
                                        setFormValues(__assign(__assign({}, formValues), { birthDate: dateString || "" }));
                                    }, error: errors.birthDate, disabled: formValues.legalForm == "LEGAL", backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY" })))),
                    React.createElement(Accordion, { label: "Adresa", open: accordionStatus.address, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Adresa", name: "publisherAddress", value: selectedAddress, disabled: addressLoading, onChange: handleAddressSelection, asyncFilter: true, onFilter: handleAddressInput, dataOptions: addressData.map(function (address) { return ({
                                        value: address.addressCode,
                                        label: address.address,
                                    }); }) })),
                            React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "center" } },
                                React.createElement(Checkbox, { label: "Povolit zad\u00E1n\u00ED hlavn\u00ED adresy", checked: enableMainAddress, onChange: function () { return setEnableMainAddress(!enableMainAddress); } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Kraj", name: "region", required: true, value: formValues.mainAddress.region.id, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, error: (_a = errors.mainAddress) === null || _a === void 0 ? void 0 : _a.region, disabled: !enableMainAddress, dataOptions: (regions || []).map(function (region) { return ({
                                        value: region.id,
                                        label: region.name,
                                    }); }) })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "Ulice", name: "street", value: formValues.mainAddress.street, error: (_b = errors.mainAddress) === null || _b === void 0 ? void 0 : _b.street, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, disabled: !enableMainAddress, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "PS\u010C", name: "postalCode", value: formValues.mainAddress.postalCode, error: (_c = errors.mainAddress) === null || _c === void 0 ? void 0 : _c.postalCode, disabled: !enableMainAddress, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "Obec", name: "municipality", value: formValues.mainAddress.municipality, error: (_d = errors.mainAddress) === null || _d === void 0 ? void 0 : _d.municipality, disabled: !enableMainAddress, onChange: function (e) { return handleAddressChange(e, "mainAddress"); }, required: true }))),
                        React.createElement(Checkbox, { label: "Li\u0161\u00ED se doru\u010Dovac\u00ED adresa?", checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }),
                        showMailingAddress && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Selection, { label: "Doru\u010Dovac\u00ED adresa", name: "mailingAddress", value: formValues.mailingAddress.region.id, disabled: mailingAddressLoading, onChange: handleMailingAddressSelection, asyncFilter: true, onFilter: handleMailingAddressInput, dataOptions: __spreadArray([], mailingAddressData.map(function (address) { return ({
                                            value: address.addressCode,
                                            label: address.address,
                                        }); }), true) })),
                                React.createElement(Col, { xs: 6, style: { display: "flex", alignItems: "center" } },
                                    React.createElement(Checkbox, { label: "Povolit zad\u00E1n\u00ED doru\u010Dovac\u00ED adresy ru\u010Dne", checked: enableMailingAddress, onChange: function () {
                                            return setEnableMailingAddress(!enableMailingAddress);
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Select, { label: "Kraj", name: "region", required: true, value: formValues.mailingAddress.region.id, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        }, error: (_e = errors.mailingAddress) === null || _e === void 0 ? void 0 : _e.region, disabled: !enableMailingAddress, dataOptions: (regions || []).map(function (region) { return ({
                                            value: region.id,
                                            label: region.name,
                                        }); }) })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextFieldTrimmed, { label: "Ulice", name: "street", value: formValues.mailingAddress.street, required: true, disabled: !enableMailingAddress, error: (_f = errors.mailingAddress) === null || _f === void 0 ? void 0 : _f.street, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextFieldTrimmed, { label: "PS\u010C", name: "postalCode", value: formValues.mailingAddress.postalCode, error: (_g = errors.mailingAddress) === null || _g === void 0 ? void 0 : _g.postalCode, required: true, disabled: !enableMailingAddress, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextFieldTrimmed, { label: "Obec", name: "municipality", value: formValues.mailingAddress.municipality, error: (_h = errors.mailingAddress) === null || _h === void 0 ? void 0 : _h.municipality, required: true, disabled: !enableMailingAddress, onChange: function (e) {
                                            return handleAddressChange(e, "mailingAddress");
                                        } })))))),
                    React.createElement(Accordion, { label: "Kontakty", open: accordionStatus.contacts, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { contacts: !accordionStatus.contacts }));
                        } },
                        formValues.contacts.map(function (contact, index) { return (React.createElement(Row, { key: index },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Select, { label: "Typ kontaktu", name: "type", required: true, value: contact.type, onChange: function (e) { return handleContactChange(index, e); }, error: errors.contacts &&
                                        errors.contacts[index] &&
                                        errors.contacts[index].type },
                                    React.createElement("option", { value: "EMAIL" }, "E-mail"),
                                    React.createElement("option", { value: "WEB" }, "Web"),
                                    React.createElement("option", { value: "PHONE" }, "Telefon"),
                                    React.createElement("option", { value: "DATA_BOX" }, "Datov\u00E1 schr\u00E1nka"))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextFieldTrimmed, { label: "Kontakt", name: "value", required: true, value: contact.value, onChange: function (e) { return handleContactChange(index, e); }, error: errors.contacts &&
                                        errors.contacts[index] &&
                                        errors.contacts[index].value })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContact(index); }, style: { marginTop: "25px" } }, "Odstranit")))); }),
                        React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt")),
                    React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } },
                        formValues.alternativeNames.map(function (alternativeName, index) {
                            var _a, _b, _c;
                            return (React.createElement(Row, { key: index },
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(TextFieldTrimmed, { label: "Alternativn\u00ED n\u00E1zev", name: "name", value: alternativeName.name, onChange: function (e) { return handleAlternativeNameChange(index, e); }, required: true, error: errors.alternativeNames &&
                                            ((_a = errors.alternativeNames[index]) === null || _a === void 0 ? void 0 : _a.name) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(TextFieldTrimmed, { label: "P\u0159\u00EDpona n\u00E1zvu" // nameSuffix field
                                        , name: "nameSuffix", value: alternativeName.nameSuffix || "", onChange: function (e) { return handleAlternativeNameChange(index, e); }, error: errors.alternativeNames &&
                                            ((_b = errors.alternativeNames[index]) === null || _b === void 0 ? void 0 : _b.nameSuffix) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(TextFieldTrimmed, { label: "Kvalifik\u00E1tor" // qualifier field
                                        , name: "qualifier", value: alternativeName.qualifier || "", onChange: function (e) { return handleAlternativeNameChange(index, e); }, error: errors.alternativeNames &&
                                            ((_c = errors.alternativeNames[index]) === null || _c === void 0 ? void 0 : _c.qualifier) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Select, { label: "Typ", name: "type", value: alternativeName.type || "", onChange: function (e) { return handleAlternativeNameChange(index, e); } },
                                        React.createElement("option", { value: "PREVIOUS" }, "P\u0159edchoz\u00ED n\u00E1zev"),
                                        React.createElement("option", { value: "IMPRINT" }, "Imprint"),
                                        React.createElement("option", { value: "ABBREVATION" }, "Zkratka"),
                                        React.createElement("option", { value: "ORGANIZATIONAL_UNIT" }, "Organiza\u010Dn\u00ED jednotka"),
                                        React.createElement("option", { value: "FOREIGN_LANGUAGE" }, "Cizojazy\u010Dn\u00FD ekvivalent"))),
                                React.createElement(Col, { xs: 2 },
                                    React.createElement(Checkbox, { label: "Neve\u0159ejn\u00E9", checked: alternativeName.nonPublic, onChange: function () {
                                            return setFormValues(__assign(__assign({}, formValues), { alternativeNames: formValues.alternativeNames.map(function (altName, i) {
                                                    return i === index
                                                        ? __assign(__assign({}, altName), { nonPublic: !altName.nonPublic }) : altName;
                                                }) }));
                                        } })),
                                React.createElement(Col, { xs: 12 },
                                    React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeName(index); }, style: { marginTop: "10px" } }, "Odstranit alternativn\u00ED n\u00E1zev"))));
                        }),
                        React.createElement(Button, { onClick: addAlternativeName }, "P\u0159idat alternativn\u00ED n\u00E1zev")),
                    React.createElement(Accordion, { label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments }));
                        } },
                        React.createElement(TextArea, { label: "Pozn\u00E1mka", name: "internalComment", value: formValues.internalComment, onChange: handleInputChange })))))));
};
export default PublisherCreate;

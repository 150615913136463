import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, MenuSection, Pane } from "@folio/stripes/components";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import AresUpdatesDetail from "./AresUpdatesDetail";
import { StripesConnectedSource } from "@folio/stripes/smart-components";
import { fetchAresUpdates } from "../../api/aresService";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var AresUpdatesList = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState(null), selectedAresUpdate = _a[0], setSelectedAresUpdate = _a[1];
    var _b = useState(true), filterPaneIsVisible = _b[0], setFilterPaneIsVisible = _b[1];
    var _c = useState(""), searchTerm = _c[0], setSearchTerm = _c[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var aresId = queryParams.get("detail");
        if (aresId) {
            fetchAresUpdates(ky).then(function (areses) {
                var ares = areses.find(function (p) { return p.id === aresId; });
                if (ares) {
                    setSelectedAresUpdate(ares);
                    // Ensure filters are preserved when opening detail
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var _d = useQuery(["ares-update-requests"], function () { return fetchAresUpdates(ky); }, {
        enabled: true,
        staleTime: 0,
        cacheTime: 0,
    }), _e = _d.data, aresUpdatesData = _e === void 0 ? [] : _e, isLoading = _d.isLoading, isError = _d.isError;
    var formatDateToEuropean = function (dateString) {
        var date = new Date(dateString);
        var day = String(date.getDate()).padStart(2, "0");
        var month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        var year = date.getFullYear();
        return "".concat(day, ".").concat(month, ".").concat(year);
    };
    // Define columns using keyof AresUpdateRequest
    var columns = useMemo(function () { return [
        {
            name: "Nakladatel",
            key: "publisher", // This is the key for the publisher object
            cellRenderer: function (item) { var _a; return ((_a = item.publisher) === null || _a === void 0 ? void 0 : _a.name) || "N/A"; }, // Render the publisher name
            clickable: true,
            onClick: function (item) {
                // Append filters to the URL when navigating to detail
                var currentParams = new URLSearchParams(location.search);
                // Remove any existing 'detail' query parameter
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedAresUpdate(item);
            },
        },
        {
            name: "Čas vytvoření",
            key: "createTime", // This is a key in AresUpdateRequest
            clickable: true,
            cellRenderer: function (item) {
                return item.createTime ? formatDateToEuropean(item.createTime) : "";
            },
        },
    ]; }, []);
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return aresUpdatesData; };
    source.resultCount = function () { return aresUpdatesData.length; };
    source.totalCount = function () { return aresUpdatesData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return true; };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace" },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "Seznam Ares aktualizac\u00ED" },
                React.createElement(TableComponent, { data: aresUpdatesData, columns: columns, pageSize: 30, hasDataFetched: function () { return true; }, searchTerm: searchTerm, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); }, source: source })),
            selectedAresUpdate && (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                    setSelectedAresUpdate(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return history.push("/brpublishers/ares-updates/edit/".concat(selectedAresUpdate.id));
                            }, fullWidth: true }, "Zm\u011Bnit")));
                } }, selectedAresUpdate.publisher ? (React.createElement(AresUpdatesDetail, { ares: selectedAresUpdate })) : (React.createElement("div", null, "No details available")))))));
};
export default AresUpdatesList;

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import PublisherList from "./PublisherList";
import PublisherEdit from "./PublisherEdit";
import PublisherCreate from "./PublisherCreate";
import PublisherClose from "./PublisherClose";
function Publishers() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: PublisherList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: PublisherCreate }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), render: function () { return React.createElement(PublisherEdit, { status: "EDIT" }); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/close/:id"), component: PublisherClose }),
        React.createElement(Route, { path: "".concat(cleanPath, "/approve/:id"), render: function () { return React.createElement(PublisherEdit, { status: "APPROVE" }); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/reject/:id"), render: function () { return React.createElement(PublisherEdit, { status: "REJECT" }); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/restore/:id"), render: function () { return React.createElement(PublisherEdit, { status: "RESTORE" }); } })));
}
export default Publishers;

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import Settings from './settings';
import AresUpdates from './routes/ares-updates/AresUpdates.tsx';
import PublisherRegistrationsList from './routes/publisher-registrations/PublisherRegistrationsList.tsx';
import PublisherUpdatesList from './routes/publisher-updates/PublisherUpdatesList.tsx';
import Publishers from './routes/publishers/Publishers.tsx';
import MyPublisherRegistration from './routes/my-publisher/MyPublisherRegistration.tsx';

class BrPublishers extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    showSettings: PropTypes.bool,
    stripes: PropTypes.shape({
      connect: PropTypes.func
    })
  };

  constructor(props) {
    super(props);

    this.connectedAresUpdates = props.stripes.connect(AresUpdates);
    this.connectedPublisherRegistrations = props.stripes.connect(PublisherRegistrationsList);
    this.connectedPublisherUpdates = props.stripes.connect(PublisherUpdatesList);
    this.connectedPublishers = props.stripes.connect(Publishers);
    this.connectedMyPublisherRegistration = props.stripes.connect(MyPublisherRegistration);
  }

  render() {
    const {
      showSettings,
      match: { path }
    } = this.props;

    if (showSettings) {
      return <Settings {...this.props} />;
    }

    return (
      <Switch>
        <Route
          path={`${path}/ares-updates`}
          exact
          component={this.connectedAresUpdates}
        />
        <Route
          path={`${path}/publisher-registrations`}
          exact
          component={this.connectedPublisherRegistrations}
        />
        <Route
          path={`${path}/publisher-updates`}
          exact
          component={this.connectedPublisherUpdates}
        />
        <Route
          path={`${path}/publishers`}
          exact
          component={this.connectedPublishers}
        />
        <Route
          path={`${path}/my-publisher`}
          exact
          component={this.connectedMyPublisherRegistration}
        />
        {/* Add a route for nested routes under /publishers */}
        <Route
          path={`${path}/publishers/*`}
          component={this.connectedPublishers}
        />
        {/* Default Redirect */}
        <Route
          render={() => <Redirect to={`${path}/publishers`} />}
        />
      </Switch>
    );
  }
}

export default BrPublishers;
